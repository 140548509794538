import { makeStyles } from "@material-ui/core";
import React from "react";
import { Switch, Route  } from "react-router-dom";
import OrderCreationPage from "../../pages/OrderCreation";

const useStyles = makeStyles(() => ({
    main: {
        height: "calc(100vh - calc(100vh - 100%))",
    }
}));

export default function MakeOrderLayout() {
    const classes = useStyles();
    return (
        <main className={classes.main}>
            <Switch>
                <Route exact path="/make-order/:id" component={OrderCreationPage} />
            </Switch>
        </main>
    );
}
