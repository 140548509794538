import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import '@fortawesome/fontawesome-free/css/all.min.css'
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: "flex",
    borderRadius: "10px",
    maxHeight: "92px",
    "& h6": {
      fontWeight: "bold",
    },
  },

  itemTitle: {
    height: "20px",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  itemPrice: {
    lineHeight: "16px",
    marginTop: "13px",
    color: "#306281",
  },
  menuItemOverview: {
    maxHeight: "71px",
    "& h6": {
      lineHeight: "unset",
      marginTop: "unset",
    }
  },
  itemAvatar: {
    marginLeft: "20px",
    width: "68px",
    height: "100%",
    "& > img": {
      transform: 'scale(1.5)',
      border: "2px solid red",
    },
    "@media (max-width: 350px)": {
      width: "53px",
      height: "83%",
    }
  },
  itemCounterWrapper: {
    position: "relative",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #D4D4D4",
    "& > h6": {
      position: "relative",
      transform: "translateY(-50%)",
      top: "50%",
    },
  }, 
  menuItemContent: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #D4D4D4",
    borderRight: '0',
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    padding: "12px 7px 13px 12px",
    width: "100%",
  }, 
  menuItemContentLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "70%",
    "& > p,h6,h5": {
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden !important",
      textOverflow: "ellipsis",
    },
  },
  menuItemControls: {
    width: "42px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
  }, 
  menuItemControlsOverview: {
    width: "52px",
  },
  menuItemButton: {
    minWidth: "unset",
    width: "100%",
    padding: "2px 0",
    fontWeight: "bold",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    color: "white",
    "& span": {
      fontSize: "20px",
    }
  },
  menuItemButtonInc: {
    backgroundColor: "#68B9E7",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "0",
    "&:hover": {
      backgroundColor: "#C3E3F5",
    }
  },
  menuItemButtonDec: {
    backgroundColor: "#F3BCC6",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "10px",
    "&:hover": {
      backgroundColor: "#FCE3E7",
    }
  },
}));

export const RestaurantMenuItem = ({theme, menuItem, handleIncItem, handleDecItem, variant}) => {
    const classes = useStyles(theme);
    return (
        <div className={`${classes.menuItem} ${variant === "overview" && classes.menuItemOverview}`}>
            <div className={classes.menuItemContent}>
                <div className={classes.menuItemContentLeft}>
                    <Typography className={classes.itemTitle} variant="h6" color="initial">{menuItem.Name}</Typography>
                    <Typography className={classes.itemDescription} color="initial">{menuItem.Description}</Typography>
                    <Typography className={classes.itemPrice} variant="h6" color="initial">{menuItem.Price}</Typography>
                </div>
                {variant !== "overview" && <Avatar className={classes.itemAvatar} alt={`${menuItem.Name} icon`} src={menuItem.Image}></Avatar>}
            </div>
            <div className={`${classes.menuItemControls} ${variant === "overview" && classes.menuItemControlsOverview}`}>
                <Button onClick={() => handleIncItem()} className={`${classes.menuItemButton} ${classes.menuItemButtonInc}`}>
                    <Icon className="fa fa-plus" />
                </Button>
                <div className={classes.itemCounterWrapper}>
                <Typography className={classes.itemCounter} variant="h6" color="initial">{menuItem.Quantity}</Typography>
                </div>
                <Button onClick={() => handleDecItem()} className={`${classes.menuItemButton} ${classes.menuItemButtonDec}`}>
                    <Icon className="fa fa-minus" />
                </Button>
            </div>
        </div>
    )
}
