import { Grid, Typography, Paper, Button, Container, FormControlLabel } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_ORDER_WITH_ITEMS, GET_RESTAURANT_BY_ID } from '../../pages/OrderCreation/graphql';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Icon from '@material-ui/core/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '@fortawesome/fontawesome-free/css/all.min.css'
import { RestaurantMenuItem } from "../RestaurantMenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  accordion: {
    margin: "2px",
    boxShadow: "initial",
    border: "none",
    backgroundColor: "unset",
    "&:before": {
      backgroundColor: "unset",
    },
    "& > .Mui-expanded": {
    }
  },
  accordionDetails: {
    paddingRight: "0",
  },
  accordionSummary: {
    "& > .Mui-expanded": {
    }
  },
  orderCreation: {
    width: "100%",
  },
  orderContainer: {
    marginBottom: "60px",
  },

  orderContainerWrapper: {
    height: "calc(100vh - calc(100vh - 100%))",
  },
  orderContainerModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - calc(100vh - 100%))",
    "& > :first-child": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  orderContent: {
    marginTop: "60px",
    maxHeight: "80%",
    marginBottom: "75px",
  },
  title: {
    margin: "6px 16px 19px",
  },
  menuTitle: {
    fontWeight: "bold",
  },
  orderModal: {
    textAlign: "center",
    padding: "25px 14px ",
    width: "100%",
    margin: "0 30px",
  },
  modalDescription: {
    fontWeight: "normal",
    color: "#4E4E4E",
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    "& > :first-child": {
      marginBottom: "15px",
    },
    "& > button": {
      minWidth: "300px",
    }
  },
  btn: {
    backgroundColor: "#68B9E7",
    color: "white",
    borderRadius: "10px",
    fontWeight: "bold",
    height: "58px",
    fontSize: "21px",
    textTransform: "capitalize",
    maxWidth: "400px",
    "&:hover": {
      backgroundColor: "#C3E3F5",
    },
    "& .fa": {
      fontSize: "18px",
    },
    "& .fa-credit-card": {
      fontSize: "18px",
    }
  },
  btnLeft: {
    display: "flex",
    justifyContent: "flex-start",
    "& .fa": {
      marginRight: "10px",
      marginLeft: "23px",
    }
  },
  payButton: {
    backgroundColor: "#68B9E7",
    color: "white",
    borderRadius: "10px",
    fontWeight: "bold",
    fontSize: "20px",
    width: "calc(100% - 48px)",
    position: "fixed",
    bottom: "16px",
    "& > span": {
      transform: "translateY(4%)",
    },
    "@media (max-width: 600px)": {
      width: "calc(100% - 32px)",
    },
    "&:hover": {
      backgroundColor: "#C3E3F5",
    }
  },
  overviewMenuWrapper: {
    padding: "0 20px",
  },
  overviewReceipt: {
    margin: "35px 10px 0px",
    padding: "10px 10px 25px",
    border: "1px solid #D4D4D4",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    "& #check-input-label > :last-child": {
      fontSize: "22px",
      fontWeight: "bold",
      marginLeft: "15px",
    },
    "& #email-input-label": {
      fontSize: "22px",
      color: "#393B3D"
    }
  },
  backgroundWrapper: {
    width: "100%",
    height: "calc(100vh - calc(100vh - 100%))",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "-1",
  },
  backgroundTop: {
    width: "140px",
    height: "140px",
    position: "absolute",
    backgroundColor: "#C3E3F5",
    borderRadius: "50%",
    right: "-30px",
    top: "-50px",
    "&::after": {
      content: '""',
      top: "-20px",
      right: "-5px",
      width: "90%",
      height: "90%",
      borderRadius: "50%",
      backgroundColor: "#8BC9ED",
      position: "absolute",
    }
  },
  backgroundBottom: {
    width: "140px",
    height: "140px",
    position: "fixed",
    backgroundColor: "#C3E3F5",
    borderRadius: "50%",
    left: "-115px",
    bottom: "20px",
    "&::after": {
      content: '""',
      bottom: "0",
      left: "-110px",
      width: "125px",
      height: "125px",
      borderRadius: "50%",
      backgroundColor: "#8BC9ED",
      opacity: "0.6",
      position: "fixed",
    }
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

export const OrderCreation = (theme) => {
  const classes = useStyles(theme);
  const [totalPrice, setTotalPrice] = useState(0.00);
  const [restaurantData, setRestaurantData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [showSelectOrderType, setShowSelectOrderType] = useState(true);
  const [showRestaurantMenu, setShowRestaurantMenu] = useState(false);
  const [showOverview, setShowOverview] = useState(false);
  const [showSelectPaymentMethod, setShowSelectPaymentMethod] = useState(false);
  const [showFinished, setFinished] = useState(false);
  const [orderState, setOrderState] = useState({
    orderType: null,
    paymentMethod: null,
  });
  const [isSendReceipt, setIsSendReceipt] = useState(false);
  const { id } = useParams();

  const [getRestaurant, { loading, data }] = useLazyQuery(
    GET_RESTAURANT_BY_ID,
    {
      fetchPolicy: "network-only",
    }
  );

  const [addOrderWithItems] = useMutation(ADD_ORDER_WITH_ITEMS);

  useEffect(() => {
    getRestaurant({
      variables: {
        id: +id,
      },
    });
  }, [getRestaurant, id]);

  const handleIncItem = (menuItem, menuIndex, itemIndex) => {
    if(showRestaurantMenu) {
      let menu = restaurantData.Menu[menuIndex];
      menu.Items[itemIndex].Quantity += 1;
      let item = menu.Items[itemIndex];
      setRestaurantData({...restaurantData, menu});
      setTotalPrice(totalPrice + item.Price);
    } else if(showOverview) {
      let newItems = [...orderData.items]
      newItems[itemIndex].Quantity += 1;
      setTotalPrice(totalPrice + menuItem.Price);
      setOrderData({
        ...orderData,
        items: newItems,
      })
    }
  }

  const handleDecItem = (menuItem, menuIndex, itemIndex) => {
    if(showRestaurantMenu) {
      let menu = restaurantData.Menu[menuIndex];
      if(menu.Items[itemIndex].Quantity !== 0) {
        menu.Items[itemIndex].Quantity -= 1;
        let item = menu.Items[itemIndex];
        setRestaurantData({...restaurantData, menu});
        setTotalPrice(totalPrice - item.Price);
      }
    } else if(showOverview) {
      if(menuItem.Quantity > 0) {
        let newItems = [...orderData.items]
        newItems[itemIndex].Quantity -= 1;
        setTotalPrice(totalPrice - menuItem.Price);
        setOrderData({
          ...orderData,
          items: newItems,
        })
      }
    }
  }

  const handlePayClick = () => {
    if(showRestaurantMenu) {
      let items = [];
      restaurantData.Menu.forEach(menu => {
        menu.Items.forEach(item => {
          if(item.Quantity > 0) items.push({
            Name: item.Name,
            Price: item.Price,
            Image: item.Image,
            Fk_Item: item.id,
            Quantity: item.Quantity
          })
        });
      });
      setOrderData({
        ...orderData,
        items: items,
        totalPrice: totalPrice,
      });
      setShowRestaurantMenu(false);
      setShowOverview(true);
    } else if (showOverview) {
      setShowOverview(false);
      setShowSelectPaymentMethod(true);
    }
  };

  const handleClickOrderType = (type) => {
    setOrderState({
      ...orderState,
      orderType: type,
    });
    setShowRestaurantMenu(true);
    setShowSelectOrderType(false);
  }

  const handleClickPaymentMethod = (method) => {
    setOrderState({
      ...orderState,
      paymentMethod: method,
    });

    let items = orderData.items.map(item => {
      if(item.Quantity > 0) return {
        Fk_Item: item.Fk_Item,
        Quantity: item.Quantity,
      }
    });
    addOrderWithItems({
      variables: {
        data: {
          ...orderData,
          items
        }
      }
    });

    setShowSelectPaymentMethod(false);
    setFinished(true);
  }

  if (loading) return <p>Loading...</p>;
  if (data && !restaurantData) {
    let menu = data.getRestaurantById.Menu.map(menu => {
      let items = menu.Items.map(item => {
        return {...item, Quantity: 0}; 
      })
      return {...menu, Items: items }
    });
    setRestaurantData({...data.getRestaurantById, Menu: menu });
    setOrderData({
      printed: 0,
      totalPrice: 0,
      state: "READY",
      Fk_Restaurant: data.getRestaurantById.id,
      clientId: "0",
      items: [],
    });
  }

  return (
    <div className={classes.orderCreation}>
      <Grid className={classes.orderContainerWrapper} container direction="row" justifyContent="center">
        {showSelectOrderType &&
          <Container className={classes.orderContainerModal}>
            <Grid container direction="row">
              <Paper className={classes.orderModal}>
                <Typography className={`${classes.menuTitle} ${classes.title}`} variant="h4" color="initial">Order type</Typography>
                <div className={classes.btnWrapper}>
                  <Button onClick={() => handleClickOrderType("here")} className={classes.btn} variant="contained" color="primary">Eat here (table 3)</Button>
                  <Button onClick={() => handleClickOrderType("takeaway")} className={classes.btn} variant="contained" color="primary">Takeaway</Button>
                </div>
              </Paper>
            </Grid>
          </Container>
        }
        {(showRestaurantMenu || showOverview) &&
        <Container className={classes.orderContainer}>
          <Grid className={classes.orderContent} item sm={12}>
            {data && restaurantData && showRestaurantMenu &&
            restaurantData.Menu.map((menu, menuIndex) =>
              <Accordion className={classes.accordion} key={`${menu.Name}+${menu.id}`}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header" >
                    <Typography className={classes.menuTitle} variant="h4" color="initial">{menu.Name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                <Grid container direction="row" spacing={2}>
                  {menu.Items.map((menuItem, itemIndex) => 
                    <Grid key={`${menuItem.Name}-${menuItem.id}`} item xs={12} md={6} lg={4}>
                      <RestaurantMenuItem
                        menuItem={menuItem}
                        handleIncItem={() => handleIncItem(menuItem, menuIndex, itemIndex)}
                        handleDecItem={() => handleDecItem(menuItem, menuIndex, itemIndex)}
                      />
                    </Grid>
                  )}
                </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {showOverview &&
              <div>
                <Typography className={`${classes.menuTitle} ${classes.title}`} variant="h4" color="initial">Overview</Typography>
                <Grid className={classes.overviewMenuWrapper} container direction="column" spacing={1}>
                  {orderData.items.map((item, itemIndex) =>
                    <Grid key={`${item.Name}-${item.id}`} item>
                      <RestaurantMenuItem
                        menuItem={item}
                        variant="overview"
                        handleIncItem={() => handleIncItem(item, 0, itemIndex)}
                        handleDecItem={() => handleDecItem(item, 0, itemIndex)}
                        />
                    </Grid>
                  )}

                </Grid>
                <div className={classes.overviewReceipt}>
                  <FormControl className="">
                    <FormControlLabel htmlFor="check-input"
                      value="Receipt"
                      id="check-input-label"
                      control={
                        <Checkbox 
                          checked={isSendReceipt}
                          id="check-input"
                          color="default"
                          icon={<Icon className="far fa-square"/>}
                          checkedIcon={<Icon className="far fa-check-square"/>}
                          onChange={() => setIsSendReceipt(!isSendReceipt)}
                        />
                      }
                      label="Receipt"
                      labelPlacement="end"
                    />

                  </FormControl>
                  <FormControl disabled={!isSendReceipt} className="">
                    <InputLabel id="email-input-label" shrink htmlFor="email-input">
                      Mail 
                    </InputLabel>
                    <BootstrapInput 
                      defaultValue=""
                      id="email-input"
                      name="email"
                      type="email"
                      autoComplete="email"
                      fullWidth
                      autoFocus
                    />
                  </FormControl>
                </div>
              </div>
            }
            {totalPrice > 0 && !showFinished &&
              <Grid container justifyContent="center">
                <Button onClick={() => handlePayClick()} className={`${classes.btn} ${classes.payButton}`} variant="contained" color="primary">Pay {totalPrice}</Button>
              </Grid>
            }
          </Grid>
        </Container>}
        {orderState.orderType && showSelectPaymentMethod &&
          <Container className={classes.orderContainerModal}>
            <Grid container direction="row">
              <Paper className={classes.orderModal}>
                <Typography className={`${classes.menuTitle} ${classes.title}`} variant="h4" color="initial">Payment Method</Typography>
                <div className={classes.btnWrapper}>
                  <Button onClick={() => handleClickPaymentMethod("creditcard")} className={`${classes.btn} ${classes.btnLeft}`} variant="contained" color="primary">
                    <Icon className="fa fa-credit-card" /> Creditcart
                  </Button>
                  <Button onClick={() => handleClickPaymentMethod("cash-debitcard")} className={`${classes.btn} ${classes.btnLeft}`} variant="contained" color="primary">
                    <Icon className="fa fa-coins" /> Cash/Debitkarte
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Container>
        }
        {showFinished &&
        <Container className={classes.orderContainerModal}>
          <Grid container direction="row">
            <Paper className={classes.orderModal}>
              <Typography className={`${classes.menuTitle} ${classes.title}`} variant="h4" color="initial">Thanks</Typography>
              <Typography className={`${classes.menuTitle} ${classes.modalDescription}`} color="initial">We will deliver your order as soon as posible</Typography>
            </Paper>
          </Grid>
        </Container>
        }
      </Grid>
      <div className={classes.backgroundWrapper}>
        <div className={classes.backgroundTop}></div>
        <div className={classes.backgroundBottom}></div>
      </div>
    </div>
  );
}
