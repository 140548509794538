import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import MakeOrderLayout from './layouts/MakeOrderLayout';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/make-order" component={MakeOrderLayout}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
