import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { ThemeProvider } from '@material-ui/core';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const devBackendUrl = "localhost:8080/graphql"; // Dev Mode
const prodBackendUrl = "myorder-api.tk/graphql" // Production mode

const hostname = window && window.location && window.location.hostname;
let graphqlURL;
let webSocketURL;

if(hostname === 'order.my-order.ch') {
  graphqlURL = `https://${prodBackendUrl}`;
  webSocketURL = `wss://${prodBackendUrl}`;
} else {
  graphqlURL = `http://${devBackendUrl}`;
  webSocketURL = `ws://${devBackendUrl}`;
}

let token = localStorage.getItem("authToken");
const httpLink = new HttpLink({
  uri: graphqlURL,
});

const wsLink = new WebSocketLink({
  uri: webSocketURL,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: token ? token : null
    },
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
