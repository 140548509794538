import { gql } from "@apollo/client";

export const GET_RESTAURANT_BY_ID = gql`
query GetRestaurantByIdQuery($id: Int!) {
  getRestaurantById(id: $id) {
    id
    Name
    City
    Phone
    Mail
    PLZ
    Category
    Phone_for_Orders
    Fk_User
    Is_Open
    Menu {
      id
      Name
      Items {
        id
        Name
        Price
        Category
        Description
        Ingredients {
          id
          Name
          Amount
          Origin
          Unit
        }
        Availability
        DiscountedPrice
        Fk_TaxCategory
        Fk_Currency
        Fk_Menu
        Image
      }
    }
  }
}
`;

export const ADD_ORDER_WITH_ITEMS = gql`
mutation Mutation($data: AddOrderWithItemsInput) {
  addOrderWithItems(orderData: $data) {
    id
  }
}
`;
