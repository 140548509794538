import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OrderCreation } from '../../components/OrderCreation';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - calc(100vh - 100%))",
    display: "flex",
  },
}));

function OrderCreationPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <OrderCreation />
    </div>
  );
}

export default OrderCreationPage;
