import { createTheme } from '@material-ui/core/styles';


const theme = createTheme({
    palette: {
        primary: {
            main: '#37a9ec'
        },
    },
});

export default theme;
